<template>
    <div>


        <nav id="nav" class="navbar navbar-expand-sm py-1 border-0" >
            <div class="mr-auto"><a href="/">
                <div class="hexagon"><img src="/img/BEE1_bee4.svg" class="logo" ></div>
            </a>

            </div>
        </nav>
        <div class="d-flex blokuprostred" >
            <div>
                <h4>{{$t('terms-conditions')}}</h4>

                <div class="papir" v-if="$i18n.locale=='cs'">

                    <a href="/Zásady ochrany osobních údajů BEE4.pdf">Zásady ochrany osobních údajů BEE4.pdf</a>
                    <hr>


                    <p class="c46"><span class="c4">Naposledy upraveno: 20.6.2020 </span></p>
                    <p class="c45"><span class="c4">Ochranu Va&scaron;eho soukrom&iacute; bereme u n&aacute;s v BEE4.0 v&aacute;&#382;n&#283;, a proto si pros&iacute;m, a&#357; u&#382; jste nov&yacute;, nebo dlouhodob&yacute; u&#382;ivatel na&scaron;&iacute; aplikace, najd&#283;te &#269;as na sezn&aacute;men&iacute; se s na&scaron;imi postupy t&yacute;kaj&iacute;c&iacute;mi se zpracov&aacute;n&iacute; a ochrany osobn&iacute;ch &uacute;daj&#367;, a pokud m&aacute;te jak&eacute;koliv dotazy, kontaktujte n&aacute;s na info@bee40.com </span>
                    </p>
                    <p class="c61"><span class="c4">Spr&aacute;vcem osobn&iacute;ch &uacute;daj&#367; je spole&#269;nost BEE4.0 s.r.o., Klu&#269;ovsk&aacute; 232, &#268;esk&yacute; Brod </span>
                    </p>
                    <p class="c51"><span class="c4">V podm&iacute;nk&aacute;ch pro pou&#382;&iacute;v&aacute;n&iacute; aplikace AI-BEE4.0 jsme se V&aacute;m zav&aacute;zali na&scaron;e nov&eacute; funkce, slu&#382;by nebo nab&iacute;dky (d&aacute;le jim &#345;&iacute;k&aacute;me souhrnn&#283; vychyt&aacute;vky nebo slu&#382;by) poskytovat tak, aby jejich u&#382;&iacute;v&aacute;n&iacute; bylo jednoduch&eacute; a pohodln&eacute;. Kdy&#382; s n&aacute;mi sd&iacute;l&iacute;te &uacute;daje, nap&#345;&iacute;klad t&iacute;m, &#382;e je vypln&iacute;te do objedn&aacute;vky slu&#382;by, budeme si je pro V&aacute;s pamatovat pro p&#345;&iacute;&scaron;t&#283;. Tak&eacute; se chceme neust&aacute;le zlep&scaron;ovat, proto t&#345;eba pravideln&#283; na anonymn&iacute; b&aacute;zi monitorujeme to, jak na&scaron;i aplikaci vyu&#382;&iacute;v&aacute;te. &Uacute;daje tak&eacute; vyu&#382;&iacute;v&aacute;me proto, abychom V&aacute;m mohli zobrazit relevantn&#283;j&scaron;&iacute; slu&#382;by a nab&iacute;dky pro V&aacute;s. Chceme, abyste p&#345;i vyu&#382;&iacute;v&aacute;n&iacute; na&scaron;ich vychyt&aacute;vek v&#283;d&#283;li, jak&yacute;m zp&#367;sobem &uacute;daje pou&#382;&iacute;v&aacute;me a jak m&#367;&#382;ete chr&aacute;nit sv&eacute; soukrom&iacute;. </span>
                    </p>
                    <p class="c48"><span class="c5">V t&#283;chto z&aacute;sad&aacute;ch ochrany osobn&iacute;ch &uacute;daj&#367; vysv&#283;tlujeme: </span>
                    </p>
                    <p class="c18"><span class="c4">Jak&eacute; &uacute;daje shroma&#382;&#271;ujeme a z jak&eacute;ho d&#367;vodu. </span>
                    </p>
                    <p class="c6"><span class="c4">Jak tyto &uacute;daje vyu&#382;&iacute;v&aacute;me. </span></p>
                    <p class="c55"><span class="c4">Jak&eacute; mo&#382;nosti nab&iacute;z&iacute;me, v&#269;etn&#283; z&iacute;sk&aacute;n&iacute; p&#345;&iacute;stupu k &uacute;daj&#367;m a jejich aktualizaci. </span>
                    </p>
                    <p class="c70"><span class="c4">Jak&aacute; m&aacute;te pr&aacute;va. </span></p>
                    <p class="c21"><span class="c4">Sna&#382;&iacute;me se, aby tyto z&aacute;sady byly co nejjednodu&scaron;&scaron;&iacute; a &#269;tiv&eacute;, pokud ale n&#283;&#269;emu nerozum&iacute;te, zeptejte se n&aacute;s na info@bee40.com </span>
                    </p>
                    <p class="c19"><span class="c5">Pro&#269; &uacute;daje shroma&#382;&#271;ujeme </span></p>
                    <p class="c37"><span class="c4">&Uacute;daje shroma&#382;&#271;ujeme a d&aacute;le s nimi pracujeme proto: </span>
                    </p>
                    <p class="c15"><span class="c4">Abychom mohli plnit sv&eacute; smluvn&iacute; z&aacute;vazky, kter&eacute; v&#367;&#269;i V&aacute;m m&aacute;me, a provozovat tuto aplikaci. </span>
                    </p>
                    <p class="c47"
                    ><span class="c4">&#381;e m&aacute;me opr&aacute;vn&#283;n&yacute; z&aacute;jem poskytovat v&scaron;em na&scaron;im u&#382;ivatel&#367;m jednoduch&eacute; a pohodln&eacute; slu&#382;by. </span>
                    </p>
                    <p class="c14"><span class="c4">&#381;e m&aacute;me opr&aacute;vn&#283;n&yacute; z&aacute;jem V&aacute;m poskytovat a nab&iacute;zet vychyt&aacute;vky, kter&eacute; odpov&iacute;daj&iacute; Va&scaron;im pot&#345;eb&aacute;m. </span>
                    </p>
                    <p class="c63"><span class="c4">&#381;e m&aacute;me opr&aacute;vn&#283;n&yacute; z&aacute;jem se s V&aacute;mi bavit o tom, jak by na&scaron;e dal&scaron;&iacute; vychyt&aacute;vky m&#283;ly vypadat. </span>
                    </p>
                    <p class="c33"><span class="c4">&#381;e m&aacute;me opr&aacute;vn&#283;n&yacute; z&aacute;jem vyhodnocovat a analyzovat to, jak na&scaron;e vychyt&aacute;vky vyu&#382;&iacute;v&aacute;te. </span>
                    </p>
                    <p class="c60"><span class="c4">Abychom splnili sv&eacute; pr&aacute;vn&iacute; povinnosti. </span></p>
                    <p class="c49"><span class="c2">&Uacute;daje, kter&eacute; shroma&#382;&#271;ujeme </span></p>
                    <p class="c17"><span class="c4">&Uacute;daje shroma&#382;&#271;ujeme n&aacute;sleduj&iacute;c&iacute;mi zp&#367;soby: </span>
                    </p>
                    <p class="c1"><span class="c4">&Uacute;daje, kter&eacute; n&aacute;m sd&#283;l&iacute;te. N&#283;kter&eacute; na&scaron;e vychyt&aacute;vky nap&#345;&iacute;klad vy&#382;aduj&iacute; uveden&iacute; adresy pro doru&#269;en&iacute;, vyfocen&iacute; dokumentu nebo jin&eacute; &uacute;daje. Tyto &uacute;daje ulo&#382;&iacute;me k Va&scaron;&iacute; osob&#283;. Poskytnut&iacute; t&#283;chto &uacute;daj&#367; je nezbytn&eacute; pro mo&#382;nost vyu&#382;it&iacute; dan&eacute; vychyt&aacute;vky a jejich neposkytnut&iacute; m&#367;&#382;e m&iacute;t za n&aacute;sledek, &#382;e aplikaci nebudete moci pln&#283; vyu&#382;&iacute;vat. Pokud n&aacute;m sd&#283;l&iacute;te &uacute;daje o t&#345;et&iacute;ch osob&aacute;ch, prohla&scaron;ujete, &#382;e tyto osoby s takov&yacute;m postupem souhlas&iacute; a jsou s n&iacute;m srozum&#283;ny. </span>
                    </p>
                    <p class="c28"><span class="c4">&Uacute;daje, kter&eacute; z&iacute;sk&aacute;v&aacute;me, kdy&#382; pou&#382;&iacute;v&aacute;te na&scaron;e slu&#382;by. Shroma&#382;&#271;ujeme &uacute;daje o slu&#382;b&aacute;ch, kter&eacute; pou&#382;&iacute;v&aacute;te, i o zp&#367;sobu, jak&yacute;m je pou&#382;&iacute;v&aacute;te, nap&#345;&iacute;klad kdy&#382; se pod&iacute;v&aacute;te na nab&iacute;dku cestovn&iacute;ho poji&scaron;t&#283;n&iacute;. Tyto &uacute;daje zahrnuj&iacute;: </span>
                    </p>
                    <p class="c67"><span class="c4">&Uacute;daje o preferovan&yacute;ch pot&#345;eb&aacute;ch </span></p>
                    <p class="c71"><span class="c4">Aplikace si m&#367;&#382;e pamatovat Va&scaron;e rozpracovan&eacute; &#382;&aacute;dosti o slu&#382;bu, abyste ji v budoucnu nemuseli znovu proch&aacute;zet. Tak&eacute; V&aacute;m m&#367;&#382;eme na z&aacute;klad&#283; Va&scaron;eho chov&aacute;n&iacute; v aplikaci nab&iacute;zet slu&#382;by, o kter&yacute;ch si mysl&iacute;me, &#382;e by V&aacute;s mohly zaj&iacute;mat. </span>
                    </p>
                    <p class="c31"><span class="c4">&Uacute;daje o za&#345;&iacute;zen&iacute; </span></p>
                    <p class="c27"><span class="c4">Shroma&#382;&#271;ujeme &uacute;daje o konkr&eacute;tn&iacute;m za&#345;&iacute;zen&iacute; (nap&#345;&iacute;klad model hardwaru, verze opera&#269;n&iacute;ho syst&eacute;mu, jedine&#269;n&eacute; identifik&aacute;tory za&#345;&iacute;zen&iacute;). Identifik&aacute;tory Va&scaron;eho za&#345;&iacute;zen&iacute; m&#367;&#382;eme p&#345;i&#345;adit k Va&scaron;&iacute; osob&#283;. Tyto &uacute;daje uchov&aacute;v&aacute;me z d&#367;vodu na&scaron;&iacute; i Va&scaron;&iacute; bezpe&#269;nosti, proto&#382;e n&aacute;m to m&#367;&#382;e ukl&aacute;dat z&aacute;kon a tak&eacute; proto, abychom V&aacute;s mohli informovat o p&#345;&iacute;padn&eacute; pot&#345;eb&#283; instalovat si novou verzi aplikace, proto&#382;e Va&scaron;i verzi opera&#269;n&iacute;ho syst&eacute;mu u&#382; nebudeme podporovat. </span>
                    </p>
                    <p class="c40"><span class="c4">&Uacute;daje z protokolu </span></p>
                    <p class="c0"><span class="c4">Kdy&#382; pou&#382;&iacute;v&aacute;te na&scaron;e slu&#382;by nebo zobrazujete obsah poskytovan&yacute; spole&#269;nost&iacute; AI-BEE4.0, automaticky shroma&#382;&#271;ujeme ur&#269;it&eacute; &uacute;daje a ukl&aacute;d&aacute;me je v protokolech server&#367;. Mezi tyto &uacute;daje pat&#345;&iacute; n&aacute;sleduj&iacute;c&iacute;: </span>
                    </p>
                    <p class="c52"><span class="c4">podrobnosti o tom, jak&yacute;m zp&#367;sobem jste na&scaron;i slu&#382;bu pou&#382;ili, nap&#345;&iacute;klad Va&scaron;e vyhled&aacute;vac&iacute; dotazy nebo historii objedn&aacute;vek; </span>
                    </p>
                    <p class="c3"><span class="c4">&uacute;daje o ud&aacute;lostech za&#345;&iacute;zen&iacute;, jako jsou selh&aacute;n&iacute;, &#269;innost syst&eacute;mu, nastaven&iacute; hardwaru, typ prohl&iacute;&#382;e&#269;e, jazyk prohl&iacute;&#382;e&#269;e, datum a &#269;as Va&scaron;eho po&#382;adavku nebo odkazuj&iacute;c&iacute; adresa URL. </span>
                    </p>
                    <p class="c32"><span class="c4">&Uacute;daje o poloze </span></p>
                    <p class="c50"><span class="c4">Kdy&#382; pou&#382;&iacute;v&aacute;te na&scaron;e slu&#382;by, m&#367;&#382;eme shroma&#382;&#271;ovat a zpracov&aacute;vat &uacute;daje o Va&scaron;&iacute; skute&#269;n&eacute; poloze. </span>
                    </p>
                    <p class="c10"><span class="c4">M&iacute;stn&iacute; &uacute;lo&#382;i&scaron;t&#283;, soubory cookies a podobn&eacute; technologie </span>
                    </p>
                    <p class="c16"><span class="c4">Pomoc&iacute; mechanism&#367;, mezi kter&eacute; pat&#345;&iacute; webov&eacute; &uacute;lo&#382;i&scaron;t&#283; prohl&iacute;&#382;e&#269;e (v&#269;etn&#283; technologie HTML 5) nebo mezipam&#283;ti za&#345;&iacute;zen&iacute;, m&#367;&#382;eme shroma&#382;&#271;ovat a uchov&aacute;vat &uacute;daje (v&#269;etn&#283; osobn&iacute;ch &uacute;daj&#367;) v m&iacute;stn&iacute;m &uacute;lo&#382;i&scaron;ti Va&scaron;eho za&#345;&iacute;zen&iacute;. D&aacute;le pou&#382;&iacute;v&aacute;me r&#367;zn&eacute; technologie ke shroma&#382;&#271;ov&aacute;n&iacute; a ukl&aacute;d&aacute;n&iacute; &uacute;daj&#367;. To m&#367;&#382;e mimo jin&eacute; zahrnovat soubory cookies nebo podobn&eacute; technologie k identifikaci Va&scaron;eho za&#345;&iacute;zen&iacute;. </span>
                    </p>
                    <p class="c56"><span class="c4">&Uacute;daje, kter&eacute; shroma&#382;&#271;ujeme, mohou b&yacute;t propojeny s Va&scaron;&iacute; osobou. V tom p&#345;&iacute;pad&#283; s nimi nakl&aacute;d&aacute;me jako s osobn&iacute;mi &uacute;daji. </span>
                    </p>
                    <p class="c43"><span class="c2">Jak pou&#382;&iacute;v&aacute;me &uacute;daje, kter&eacute; shroma&#382;&#271;ujeme </span>
                    </p>
                    <p class="c7"><span class="c4">&Uacute;daje, kter&eacute; shroma&#382;&#271;ujeme p&#345;i Va&scaron;em vyu&#382;&iacute;v&aacute;n&iacute; na&scaron;ich slu&#382;eb, pou&#382;&iacute;v&aacute;me k jejich poskytov&aacute;n&iacute;, udr&#382;ov&aacute;n&iacute;, ochran&#283; a zlep&scaron;ov&aacute;n&iacute;, k vyv&iacute;jen&iacute; nov&yacute;ch slu&#382;eb a k ochran&#283; spole&#269;nosti AI-BEE4.0 i jej&iacute;ch u&#382;ivatel&#367;. Tyto &uacute;daje rovn&#283;&#382; pou&#382;&iacute;v&aacute;me, abychom V&aacute;m mohli poskytnout obsah odpov&iacute;daj&iacute;c&iacute; Va&scaron;im pot&#345;eb&aacute;m &ndash; jako nap&#345;&iacute;klad relevantn&#283;j&scaron;&iacute; nab&iacute;dku slu&#382;eb. K t&#283;mto zobrazen&iacute;m m&#367;&#382;e doj&iacute;t i v reklamn&iacute;m kontextu. Budeme respektovat Va&scaron;e volby, kter&yacute;mi v aplikaci omez&iacute;te zobrazov&aacute;n&iacute; nab&iacute;dek a reklam. </span>
                    </p>
                    <p class="c41"><span class="c4">Kdy&#382; n&aacute;s budete kontaktovat, m&#367;&#382;eme si ponechat z&aacute;znam o komunikaci, abychom V&aacute;m byli schopni pomoci p&#345;i &#345;e&scaron;en&iacute; jak&yacute;chkoli p&#345;&iacute;padn&yacute;ch probl&eacute;m&#367;. Va&scaron;i e-mailovou adresu m&#367;&#382;eme pou&#382;&iacute;t k zas&iacute;l&aacute;n&iacute; informac&iacute; o na&scaron;ich slu&#382;b&aacute;ch, jako nap&#345;&iacute;klad sd&#283;len&iacute; o chystan&yacute;ch zm&#283;n&aacute;ch &#269;i vylep&scaron;en&iacute;ch, nebo pro zas&iacute;l&aacute;n&iacute; nab&iacute;dky slu&#382;eb. </span>
                    </p><p class="c20"><span class="c4">P&#345;ed pou&#382;it&iacute;m &uacute;daj&#367; za &uacute;&#269;elem, kter&yacute; nen&iacute; uveden v t&#283;chto z&aacute;sad&aacute;ch ochrany osobn&iacute;ch &uacute;daj&#367; a nevyplyne pro n&aacute;s ze z&aacute;kona, V&aacute;s v&#382;dy po&#382;&aacute;d&aacute;me o souhlas. </span>
                </p>
                    <p class="c26"><span class="c4">Jak dlouho jsou u n&aacute;s &uacute;daje ulo&#382;eny a kde </span></p>
                    <p class="c13"><span class="c4">&Uacute;daje, kter&eacute; shroma&#382;&#271;ujeme p&#345;i Va&scaron;em vyu&#382;&iacute;v&aacute;n&iacute; na&scaron;ich slu&#382;eb, zpracov&aacute;v&aacute;me a m&aacute;me ulo&#382;eny po celou dobu, kdy vyu&#382;&iacute;v&aacute;te na&scaron;i aplikaci a dal&scaron;&iacute; t&#345;i roky pot&eacute;, co vyu&#382;&iacute;v&aacute;n&iacute; ukon&#269;&iacute;te, nen&iacute;-li z&aacute;konem vy&#382;adov&aacute;na del&scaron;&iacute; doba. Spole&#269;nost AI-BEE4.0 zpracov&aacute;v&aacute; osobn&iacute; &uacute;daje na sv&yacute;ch serverech v &#268;esk&eacute; republice. </span>
                    </p>
                    <p class="c30"><span class="c4">Transparentnost a volba </span></p>
                    <p class="c39"><span class="c4">Lid&eacute; maj&iacute; ve vztahu k soukrom&iacute; r&#367;zn&eacute; obavy. Na&scaron;&iacute;m c&iacute;lem je, aby bylo jasn&eacute;, jak&eacute; &uacute;daje shroma&#382;&#271;ujeme, a d&iacute;ky tomu jste m&#283;li mo&#382;nost racion&aacute;ln&#283; zvolit zp&#367;sob, jak&yacute;m budou vyu&#382;&iacute;v&aacute;ny. M&#367;&#382;ete nap&#345;&iacute;klad: </span>
                    </p>
                    <p class="c35"><span class="c4">Podat n&aacute;mitku, pokud m&aacute;te pocit, &#382;e Va&scaron;e &uacute;daje nezpracov&aacute;v&aacute;me spr&aacute;vn&#283;, a &#382;&aacute;dat o vysv&#283;tlen&iacute; &#269;i napraven&iacute; stavu, bude-li n&aacute;mitka d&#367;vodn&aacute;. </span>
                    </p>
                    <p class="c59"><span class="c2">Va&scaron;e pr&aacute;va </span></p>
                    <p class="c51"><span class="c4">P&#345;&iacute;stup k osobn&iacute;m &uacute;daj&#367;m V&aacute;m chceme poskytnout poka&#382;d&eacute;, kdy&#382; pou&#382;&iacute;v&aacute;te na&scaron;e vychyt&aacute;vky. Va&scaron;e aktu&aacute;ln&iacute; &uacute;daje p&#345;i objedn&aacute;vk&aacute;ch vychyt&aacute;vek v&#382;dy zobrazujeme tak, jak n&aacute;m byly poskytnuty V&aacute;mi nebo Air Bank. Pokud jsou &uacute;daje p&#345;edvypln&#283;ny nespr&aacute;vn&#283;, sna&#382;&iacute;me se V&aacute;m nab&iacute;dnout mo&#382;nost jejich rychl&eacute; aktualizace. P&#345;i aktualizaci osobn&iacute;ch &uacute;daj&#367; V&aacute;s m&#367;&#382;eme po&#382;&aacute;dat o ov&#283;&#345;en&iacute; toto&#382;nosti, ne&#382; se budeme moci Va&scaron;emu po&#382;adavku v&#283;novat. </span>
                    </p>
                    <p class="c34"><span class="c4">M&#367;&#382;eme zam&iacute;tnout po&#382;adavky, kter&eacute; se bezd&#367;vodn&#283; opakuj&iacute;, vy&#382;aduj&iacute; nep&#345;im&#283;&#345;en&eacute; technick&eacute; &uacute;sil&iacute; (nap&#345;&iacute;klad vyvinut&iacute; nov&eacute;ho syst&eacute;mu nebo z&aacute;sadn&iacute; zm&#283;nu st&aacute;vaj&iacute;c&iacute;ho postupu), ohro&#382;uj&iacute; soukrom&iacute; ostatn&iacute;ch nebo by byly velmi obt&iacute;&#382;n&#283; provediteln&eacute; (nap&#345;&iacute;klad po&#382;adavky souvisej&iacute;c&iacute; s &uacute;daji v z&aacute;lo&#382;n&iacute;ch syst&eacute;mech). </span>
                    </p>
                    <p class="c44"><span class="c4">P&#345;&iacute;stup k &uacute;daj&#367;m a jejich oprav&#283; V&aacute;m budeme poskytovat zdarma. Na&scaron;&iacute;m c&iacute;lem je poskytovat slu&#382;by zp&#367;sobem, kter&yacute; &uacute;daje ochr&aacute;n&iacute; p&#345;ed n&aacute;hodn&yacute;m &#269;i z&aacute;m&#283;rn&yacute;m zni&#269;en&iacute;m. Vzhledem k t&eacute;to skute&#269;nosti </span>
                    </p>
                    <p class="c42"><span class="c4">je mo&#382;n&eacute;, &#382;e pot&eacute;, co sv&eacute; &uacute;daje z na&scaron;ich slu&#382;eb sma&#382;ete, jejich rezidu&aacute;ln&iacute; kopie ihned nesma&#382;eme z na&scaron;ich aktivn&iacute;ch server&#367; a &uacute;daje neodebereme z na&scaron;ich z&aacute;lo&#382;n&iacute;ch syst&eacute;m&#367;. </span>
                    </p>
                    <p class="c12"><span
                            class="c4">V p&#345;&iacute;pad&#283;, &#382;e m&aacute;te pocit, &#382;e s Va&scaron;imi osobn&iacute;mi &uacute;daji nenakl&aacute;d&aacute;me spr&aacute;vn&#283;, m&#367;&#382;ete se se svoj&iacute; st&iacute;&#382;nost&iacute; obr&aacute;tit na dozorov&yacute; org&aacute;n &ndash; &Uacute;&#345;ad pro ochranu osobn&iacute;ch &uacute;daj&#367;, Pplk. Sochora 27, 170 00 Praha 7, www.uoou.cz. </span>
                    </p>
                    <p class="c29"><span class="c4">&Uacute;daje, kter&eacute; sd&iacute;l&iacute;me s t&#345;et&iacute;mi osobami </span>
                    </p>
                    <p class="c25"><span class="c4">Osobn&iacute; &uacute;daje nesd&iacute;l&iacute;me se spole&#269;nostmi, organizacemi ani jednotlivci mimo spole&#269;nost AI-BEE4.0, pokud nedojde k n&#283;kter&eacute;mu z n&aacute;sleduj&iacute;c&iacute;ch p&#345;&iacute;pad&#367;: </span>
                    </p>
                    <p class="c54"><span class="c4">S Va&scaron;&iacute;m souhlasem </span></p>
                    <p class="c34"><span class="c4">Pokud n&aacute;m k tomu d&aacute;te souhlas, budeme osobn&iacute; &uacute;daje sd&iacute;let se spole&#269;nostmi, organizacemi nebo jednotlivci mimo spole&#269;nost AI-BEE4.0, zejm&eacute;na s obchodn&iacute;ky. V p&#345;&iacute;pad&#283;, kdy by m&#283;lo doj&iacute;t ke sd&iacute;len&iacute; citliv&yacute;ch osobn&iacute;ch &uacute;daj&#367; (nap&#345;. &uacute;daj&#367; o Va&scaron;em zdravotn&iacute;m stavu), po&#382;adujeme V&aacute;&scaron; v&yacute;slovn&yacute; souhlas. </span>
                    </p>
                    <p class="c22"><span class="c4">Z pr&aacute;vn&iacute;ch d&#367;vod&#367; </span></p>
                    <p class="c66"><span class="c4">Osobn&iacute; &uacute;daje sd&iacute;l&iacute;me se spole&#269;nostmi, organizacemi &#269;i jednotlivci mimo spole&#269;nost AI-BEE4.0, pokud jsme v dobr&eacute; v&iacute;&#345;e p&#345;esv&#283;d&#269;eni, &#382;e p&#345;&iacute;stup k takov&yacute;m &uacute;daj&#367;m, jejich pou&#382;it&iacute;, uchov&aacute;n&iacute; nebo zve&#345;ejn&#283;n&iacute; jsou rozumn&#283; nutn&eacute; za &uacute;&#269;elem: </span>
                    </p>
                    <p class="c62"><span class="c4">dodr&#382;en&iacute; platn&yacute;ch z&aacute;kon&#367; &#269;i na&#345;&iacute;zen&iacute; a projedn&aacute;v&aacute;n&iacute; soudn&iacute;ho &#345;&iacute;zen&iacute; nebo vymahateln&yacute;ch &#382;&aacute;dost&iacute; st&aacute;tn&iacute; spr&aacute;vy, </span>
                    </p>
                    <p class="c36"><span class="c4">uplatn&#283;n&iacute; p&#345;&iacute;slu&scaron;n&yacute;ch smluvn&iacute;ch podm&iacute;nek v&#269;etn&#283; vy&scaron;et&#345;en&iacute; jejich mo&#382;n&eacute;ho poru&scaron;en&iacute;, </span>
                    </p>
                    <p class="c69"><span class="c4">zji&scaron;t&#283;n&iacute;, zabr&aacute;n&#283;n&iacute; nebo jin&eacute;ho postupu proti podvodu, technick&yacute;m pot&iacute;&#382;&iacute;m &#269;i bezpe&#269;nostn&iacute;m probl&eacute;m&#367;m, </span>
                    </p>
                    <p class="c8"><span class="c4">ochrany p&#345;ed po&scaron;kozen&iacute;m pr&aacute;v, majetku nebo bezpe&#269;nosti spole&#269;nosti AI-BEE4.0, na&scaron;ich u&#382;ivatel&#367; nebo ve&#345;ejnosti tak, jak to vy&#382;aduje nebo povoluje z&aacute;kon. </span>
                    </p>
                    <p class="c11"><span class="c4">Pokud se spole&#269;nost AI-BEE4.0 z&uacute;&#269;astn&iacute; slou&#269;en&iacute; &#269;i akvizice nebo prod&aacute; sv&#367;j majetek, zajist&iacute;me i n&aacute;sledn&#283; d&#367;v&#283;rnost v&scaron;ech osobn&iacute;ch &uacute;daj&#367; a p&#345;edt&iacute;m, ne&#382; budou osobn&iacute; &uacute;daje p&#345;evedeny nebo se na n&#283; za&#269;nou vztahovat jin&eacute; z&aacute;sady ochrany osobn&iacute;ch &uacute;daj&#367;, tuto skute&#269;nost doty&#269;n&yacute;m u&#382;ivatel&#367;m ozn&aacute;m&iacute;me. </span>
                    </p>
                    <p class="c23"><span class="c2">Zabezpe&#269;en&iacute; &uacute;daj&#367; </span></p>
                    <p class="c53"><span class="c4">Usilovn&#283; pracujeme na tom, abychom n&aacute;s i u&#382;ivatele na&scaron;&iacute; aplikace ochr&aacute;nili p&#345;ed neopr&aacute;vn&#283;n&yacute;m p&#345;&iacute;stupem nebo neopr&aacute;vn&#283;n&yacute;m pozm&#283;&#328;ov&aacute;n&iacute;m, zve&#345;ejn&#283;n&iacute;m nebo zni&#269;en&iacute;m &uacute;daj&#367;, kter&eacute; uchov&aacute;v&aacute;me. Zejm&eacute;na: </span>
                    </p>
                    <p class="c57"><span class="
c4">Procesy shroma&#382;&#271;ov&aacute;n&iacute;, ukl&aacute;d&aacute;n&iacute; a zpracov&aacute;v&aacute;n&iacute; &uacute;daj&#367;, v&#269;etn&#283; fyzick&eacute;ho zabezpe&#269;en&iacute;, pe&#269;liv&#283; kontrolujeme, abychom p&#345;ede&scaron;li neopr&aacute;vn&#283;n&eacute;mu p&#345;&iacute;stupu do na&scaron;ich syst&eacute;m&#367;. </span>
                    </p>
                    <p class="c64"><span class="c4">P&#345;&iacute;stup k osobn&iacute;m &uacute;daj&#367;m omezujeme pouze na zam&#283;stnance spole&#269;nosti AI-BEE4.0, smluvn&iacute; partnery a z&aacute;stupce, kte&#345;&iacute; tyto &uacute;daje pot&#345;ebuj&iacute; zn&aacute;t, aby je pro n&aacute;s mohli zpracovat, a kte&#345;&iacute; jsou smluvn&#283; v&aacute;z&aacute;ni p&#345;&iacute;sn&yacute;mi povinnostmi zachov&aacute;vat d&#367;v&#283;rnost a k jejich&#382; potrest&aacute;n&iacute; &#269;i k ukon&#269;en&iacute; spolupr&aacute;ce s nimi m&#367;&#382;e v p&#345;&iacute;pad&#283; poru&scaron;en&iacute; tohoto z&aacute;vazku doj&iacute;t. </span>
                    </p>
                    <p class="c9"><span class="c4">Soulad s p&#345;edpisy a spolupr&aacute;ce s regula&#269;n&iacute;mi org&aacute;ny </span>
                    </p>
                    <p class="c24"><span class="c4">Dodr&#382;ov&aacute;n&iacute; z&aacute;sad ochrany soukrom&iacute; pravideln&#283; kontrolujeme. Kdy&#382; obdr&#382;&iacute;me form&aacute;ln&iacute; p&iacute;semnou st&iacute;&#382;nost, budeme osobu, kter&aacute; ji podala, kontaktovat za &uacute;&#269;elem pro&scaron;et&#345;en&iacute;. St&iacute;&#382;nost vy&#345;&iacute;d&iacute;me v z&aacute;konn&yacute;ch lh&#367;t&aacute;ch, zpravidla do 30 dn&#367;. </span>
                    </p>
                    <p class="c21"><span class="c4">Na&scaron;e z&aacute;sady ochrany osobn&iacute;ch &uacute;daj&#367; mohou b&yacute;t &#269;as od &#269;asu zm&#283;n&#283;ny. Va&scaron;e pr&aacute;va vypl&yacute;vaj&iacute;c&iacute; z t&#283;chto z&aacute;sad ochrany osobn&iacute;ch &uacute;daj&#367; bez Va&scaron;eho v&yacute;slovn&eacute;ho souhlasu neomez&iacute;me. V&scaron;echny zm&#283;ny z&aacute;sad ochrany osobn&iacute;ch &uacute;daj&#367; zve&#345;ejn&iacute;me na t&eacute;to str&aacute;nce, a pokud se bude jednat o v&yacute;znamn&eacute; zm&#283;ny, budeme V&aacute;s informovat v&yacute;razn&#283;ji (v p&#345;&iacute;pad&#283; n&#283;kter&yacute;ch slu&#382;eb m&#367;&#382;eme zm&#283;ny z&aacute;sad ochrany osobn&iacute;ch &uacute;daj&#367; oznamovat e-mailem). P&#345;edchoz&iacute; verze t&#283;chto z&aacute;sad ochrany osobn&iacute;ch &uacute;daj&#367; tak&eacute; uchov&aacute;me v archivu, kde si je budete moci p&#345;e&#269;&iacute;st. </span>
                    </p>
                    <p class="c58"><span class="c4">Postupy vztahuj&iacute;c&iacute; se na konkr&eacute;tn&iacute; slu&#382;by </span>
                    </p>
                    <p class="c72"><span class="c4">N&iacute;&#382;e budeme postupn&#283; p&#345;id&aacute;vat popis postup&#367; v oblasti ochrany osobn&iacute;ch &uacute;daj&#367; souvisej&iacute;c&iacute; s nov&#283; p&#345;id&aacute;van&yacute;mi slu&#382;bami a vychyt&aacute;vkami, kter&eacute; bude AI-BEE4.0 v aplikaci nab&iacute;zet. </span>
                    </p>


                </div>

                <div v-else class="papir" >
                    <a href="/BEE4 conditions-EN.pdf">BEE4 conditions-EN.pdf</a>
                    <hr>
                    <p class="c24"><span class="c1">Last modified: 20.6.2020 </span></p>
                    <p class="c11"><span class="c1">We take the protection of your privacy with us at BEE4.0, so please, whether you are a new or long- term user of our application, take the time to familiarize yourself with our procedures regarding the processing and protection of personal data, and if you have any questions, contact us at info@bee40.com </span>
                    </p>
                    <p class="c50"><span class="c1">The administrator of personal data is the company BEE4.0 s.r.o., Klu&#269;ovsk&aacute; 232, &#268;esk&yacute; Brod </span>
                    </p>
                    <p class="c34 c53"><span class="c1">In the conditions for using the AI-BEE4.0 application, we are committed to providing you with our new functions, services or offers (hereinafter collectively referred to as gadgets or services) so that their use is simple and convenient. If you share data with us, for example by filling it in the service order, we will remember it for you next time. We also want to constantly improve, so we regularly monitor how you use our application on an anonymous basis. We also use the data to show you more relevant services and offers for you. When using our tweaks, we want you to know how we use the data and how you can protect your privacy. </span>
                    </p>
                    <p class="c34 c66"><span class="c1">In this privacy policy, we explain: </span></p>
                    <p class="c34 c45"><span class="c1">What data do we collect and for what reason. </span></p>
                    <p class="c20"><span class="c1">How we use this data. </span></p>
                    <p class="c27"><span class="c1">What options do we offer, including gaining access to data and updating it. </span>
                    </p>
                    <p class="c0"><span class="c1">What are your rights? </span></p>
                    <p class="c34 c56"><span class="c1">We try to make these principles as simple and readable as possible, but if you do not understand something, ask us at info@bee40.com </span>
                    </p>
                    <p class="c34 c47"><span class="c1">Why we collect data </span></p>
                    <p class="c31"><span class="c1">We collect data and work with them therefore: </span></p>
                    <p class="c35"><span class="c1">In order to fulfill our contractual obligations to you and to operate this application. </span>
                    </p>
                    <p class="c34 c56"><span class="c1">That we have a legitimate interest in providing all our users with simple and convenient services. </span>
                    </p>
                    <p class="c3"><span class="c1">That we have a legitimate interest in providing you with and offering gadgets that meet your needs. </span>
                    </p>
                    <p class="c19"><span class="c1">That we have a legitimate interest in talking to you about what our next gadgets should look like. </span>
                    </p>
                    <p class="c15"><span class="c1">That we have a legitimate interest in evaluating and analyzing how you use our tweaks. </span>
                    </p>
                    <p class="c30"><span class="c1">To fulfill our legal obligations. </span></p>
                    <p class="c10"><span class="c1">The data we collect </span></p>
                    <p class="c34 c36"><span class="c1">We collect data in the following ways: </span></p>
                    <p class="c34 c64"><span class="c1">The information you provide to us. For example, some of our tweaks require you to provide a delivery address, a photo of a document, or other information. We will store this data for you. Providing this information is necessary to be able to use the feature and not providing it may result in you not being able to take full advantage of the application. If you provide us with information about third parties, you declare that these people agree with this procedure and understand it. </span>
                    </p>
                    <p class="c12"><span class="c1">The information we collect when you use our services. We collect information about the services you use and the way you use them, for example when you look at the travel insurance offer. These data include: </span>
                    </p>
                    <p class="c9"><span class="c1">Data on preferred needs </span></p>
                    <p class="c8"><span class="c1">The application may remember your pending service requests so that you do not have to go through it again in the future. We may also offer you services that we think may be of interest to you based on your behavior in the application. </span>
                    </p>
                    <p class="c54"><span class="c1">Device data </span></p>
                    <p class="c34"><span class="c1">We collect information about a specific device (for example, hardware model, operating system version, unique device identifiers). We can assign the identifiers of your device to your person. We store this data for our own and your security reasons, as this may be required by law and also so that we can inform you of any need to install a new version of the application, as we will no longer support your version of the operating system. </span>
                    </p>
                    <p class="c43"><span class="c1">Data from the log </span></p>
                    <p class="c34 c63"><span class="c1">When you use our services or view content provided by AI-BEE4.0, we automatically collect certain data and store it in server logs. These data include the following: </span>
                    </p>
                    <p class="c34 c60"><span class="c1">details about how you used our service, such as your search queries or order history; </span>
                    </p>
                    <p class="c34 c55"><span class="c1">device event information such as failure, system activity, hardware settings, browser type, browser language, date and time of your request, or referring URL. </span>
                    </p>
                    <p class="c6"><span class="c1">Location data </span></p>
                    <p class="c34 c49"><span class="c1">When you use our services, we may collect and process data about your actual location. </span>
                    </p>
                    <p class="c7"><span class="
c1">Local storage, cookies and similar technologies </span></p>
                    <p class="c25"><span class="c1">Using mechanisms such as browser web storage (including HTML 5) or device caching, we may collect and store data (including personal information) in your device&#39;s local storage. We also use various technologies to collect and store data. This may include, but is not limited to, cookies or similar technologies to identify your device. </span>
                    </p>
                    <p class="c13"><span class="c1">The data we collect may be linked to your person. In that case, we treat them as personal data. </span>
                    </p>
                    <p class="c16"><span class="c1">How we use the data we collect </span></p>
                    <p class="c17"><span class="c1">We use the information we collect when you use our services to provide, maintain, protect and improve it, to develop new services and to protect AI-BEE4.0 and its users. We also use this information to provide you with content that meets your needs - such as a more relevant range of services. These impressions may also occur in the advertising context. We will respect your choices to limit the display of offers and ads in the application. </span>
                    </p>
                    <p class="c34 c38"><span class="c1">When you contact us, we may keep a record of your communication to assist you in resolving any issues. We may use your email address to send you information about our services, such as notifications of upcoming changes or improvements, or to send you an offer of services. </span>
                    </p>
                    <p class="c51"><span class="c1">We will always ask for your consent before using the data for a purpose that is not stated in this privacy policy and does not follow us from the law. </span>
                    </p>
                    <p class="c34 c42"><span class="c1">How long the data is stored with us and where </span></p>
                    <p class="c5"><span class="c1">The data that we collect when you use our services is processed and stored for the entire period you use our application and for another three years after you stop using it, unless a longer period is required by law. AI-BEE4.0 processes personal data on its servers in the Czech Republic. </span>
                    </p>
                    <p class="c34 c69"><span class="c1">Transparency and choice </span></p>
                    <p class="c34 c46"><span class="c1">People have different concerns about privacy. Our goal is to make it clear what data we collect, so that you have the opportunity to rationally choose how it will be used. For example, you can: </span>
                    </p>
                    <p class="c34 c48"><span class="c1">File an objection if you feel that we are not processing your data correctly, and ask for an explanation or correction if the objection is justified. </span>
                    </p>
                    <p class="c34 c44"><span class="c1">Your rights </span></p>
                    <p class="c4"><span class="c1">We want to give you access to personal information every time you use our gadgets. When ordering gadgets, we always display your current data as it was provided to us by you or Air Bank. If the data is pre-filled incorrectly, we try to offer you the possibility to update it quickly. When updating personal information, we may ask you to verify your identity before we can process your request. </span>
                    </p>
                    <p class="c64 c68"><span class="c1">We may reject requests that are unreasonably repetitive, require disproportionate technical effort (such as developing a new system or fundamentally changing an existing process), compromise the privacy of others, or be very difficult to implement (such as data requirements in backup systems). </span>
                    </p>
                    <p class="c34 c41"><span class="c1">We will provide you with access to the data and their correction free of charge. Our goal is to provide services in a way that protects data from accidental or intentional destruction. Due to this fact </span>
                    </p>
                    <p class="c14"><span class="c1">It is possible that after you delete your data from our services, we will not immediately delete residual copies from our active servers and remove the data from our backup systems. </span>
                    </p>
                    <p class="c29"><span class="c1">If you feel that we are not handling your personal data correctly, you can file a complaint with your supervisory body - the Office for Personal Data
Protection, Lt. Col. Sochora 27, 170 00 Prague 7, www.uoou.cz. </span></p>
                    <p class="c26"><span class="c1">Data we share with third parties </span></p>
                    <p class="c21"><span class="c1">We do not share personal information with companies, organizations or individuals outside of AI- BEE4.0 unless one of the following occurs: </span>
                    </p>
                    <p class="c34 c61"><span class="c1">With your consent </span></p>
                    <p class="c57"><span class="c1">If you give us your consent, we will share personal information with companies, organizations or individuals outside AI-BEE4.0, especially merchants. In the event that sensitive personal data (eg data on your health condition) should be shared, we require your express consent. </span>
                    </p>
                    <p class="c18"><span class="c1">For legal reasons </span></p>
                    <p class="c11"><span class="c1">We share personal information with companies, organizations or individuals outside AI-BEE4.0 if we believe in good faith that access to, use, retention or disclosure of such information is reasonably necessary in order to: </span>
                    </p>
                    <p class="c67"><span class="c1">compliance with applicable laws or regulations and consideration of court proceedings or enforceable requests from the state administration, </span>
                    </p>
                    <p class="c34 c62"><span class="c1">application of the relevant contractual conditions, including an investigation of their possible breach, </span>
                    </p>
                    <p class="c34 c70"><span class="c1">detection, prevention or other action against fraud, technical difficulties or security problems, </span>
                    </p>
                    <p class="c58"><span class="c1">protection against damage to the rights, property or safety of AI-BEE4.0, our users or the public as required or permitted by law. </span>
                    </p>
                    <p class="c65"><span class="c1">If AI-BEE4.0 participates in a merger or acquisition or sells its assets, we will continue to ensure the confidentiality of all personal data and will notify the users concerned before the personal data is transferred or subject to other privacy policies. </span>
                    </p>
                    <p class="c34 c37"><span class="c1">Data security </span></p>
                    <p class="c34 c52"><span class="c1">We work hard to protect us and the users of our application from unauthorized access or unauthorized alteration, disclosure or destruction of the data we store. Especially: </span>
                    </p>
                    <p class="c22"><span class="c1">We carefully control the processes of data collection, storage and processing, including physical security, to prevent unauthorized access to our systems. </span>
                    </p>
                    <p class="c33"><span class="c1">We restrict access to personal data only to AI-BEE4.0 employees, contractual partners and representatives who need to know this data in order to process it for us and who are contractually bound by strict obligations of confidentiality and whose punishment or termination of cooperation with they may occur in the event of a breach of this obligation. </span>
                    </p>
                    <p class="c34 c39"><span class="c1">Regulatory compliance and regulatory cooperation </span></p>
                    <p class="c40"><span class="c1">We regularly monitor compliance with our privacy policy. When we receive a formal written complaint, we will contact the person who made it for investigation. We will handle the complaint within the legal deadlines, usually within 30 days. </span>
                    </p>
                    <p class="c32"><span class="c1">Our privacy policy may change from time to time. We will not limit your rights under this privacy policy without your express consent. We will post all changes to our privacy policy on this page, and if there are significant changes, we will notify you more strongly (for some services, we may notify you of changes to your privacy policy by email). We will also keep previous versions of this privacy policy in the archive, where you will be able to read them. </span>
                    </p>
                    <p class="c34 c59"><span class="c1">Procedures applicable to specific services </span></p>
                    <p class="c34 c46"><span class="c1">Below, we will gradually add a description of privacy practices related to the newly added services and tweaks that AI-BEE4.0 will offer in the application. </span>
                    </p>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "PodminkyPouziti"
    }
</script>

<style scoped lang="scss">
    .blokuprostred{
        max-width: 800px;
        margin: 120px auto;
        font-size: 16px;


    }

    p{
        text-align: justify;
    }
    .c2 {
        color: #000000;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;

        font-style: normal;
    }

    .c5 {
        color: #000000;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;

        font-style: italic;
    }

</style>
